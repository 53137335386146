@font-face {
  font-family: 'canela-light';
  src: url('/fonts/CanelaText-Light-Trial.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'canela-bold';
  src: url('/fonts/CanelaText-Bold-Trial.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'hoves-regular';
  src: url('/fonts/TTHoves-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'hoves-medium';
  src: url('/fonts/TTHoves-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'hoves-bold';
  src: url('/fonts/TTHoves-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'fields-medium';
  src: url('/fonts/Fields-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sofia-pro-regular';
  src: url('/fonts/SofiaPro-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'inter-bold';
  src: url('/fonts/Inter-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'inter-regular';
  src: url('/fonts/Inter-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'inter-medium';
  src: url('/fonts/Inter-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'pp-hatton-medium';
  src: url('/fonts/PPHatton-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'satoshi-regular';
  src: url('/fonts/Satoshi-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'satoshi-medium';
  src: url('/fonts/Satoshi-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'satoshi-bold';
  src: url('/fonts/Satoshi-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ginto-nord-regular';
  src: url('/fonts/GintoNord-Regular.woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppins-medium';
  src: url('/fonts/Poppins-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppins-regular';
  src: url('/fonts/Poppins-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'philosopher-bold';
  src: url('/fonts/Philosopher-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gantari-light';
  src: url('/fonts/Gantari-Light.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'abc-prophet-regular';
  src: url('/fonts/ABCProphet-Regular.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'apercu-bold-pro';
  src: url('../../../admin/public/fonts/apercu-bold-pro.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'apercu-medium-pro';
  src: url('../../../admin/public/fonts/apercu-medium-pro.woff2')
    format('woff2');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'apercu-mono-regular-pro';
  src: url('../../../admin/public/fonts/apercu-mono-regular-pro.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'apercu-regular-pro';
  src: url('../../../admin/public/fonts/apercu-regular-pro.woff2')
    format('woff2');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'lexend-medium';
  src: url('/fonts/Lexend-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'lexend-regular';
  src: url('/fonts/Lexend-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'dm-serif-display-regular';
  src: url('/fonts/DMSerifDisplay-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}
